import React, { useState } from 'react';
import Typing from 'react-typing-animation';
import { useInView } from 'react-intersection-observer';

const DashComplete = ({
  defaultClass,
  visibleClass,
}: {
  defaultClass: string;
  visibleClass: string;
}) => {
  const [ref, inView] = useInView({
    rootMargin: '0px 0px -100px 0px',
    triggerOnce: true,
  });
  const [typeComplete, setTypeComplete] = useState(false);
  const className = inView ? `${defaultClass} ${visibleClass}` : defaultClass;
  return (
    <div
      ref={ref}
      className={className}
    >
      <div className="dash-home-complete__content">
        <div className="dash-home-complete__text">
          {inView ? (
            <Typing speed={20} startDelay={500} hideCursor={true} onFinishedTyping={() => setTypeComplete(true)}>
              We built the page to explain how Dash works
              <Typing.Delay ms={140} />
              —
              <Typing.Delay ms={220} />
              with Dash!
            </Typing>
          ) : null}
          <img alt="Emoji" style={{ opacity: typeComplete ? 1 : 0, transform: `scale(${typeComplete ? 1 : 0.8}` }} className='dash-home-complete__emoji' src={require('../assets/images/tada.png')} />
        </div>
        <img alt="background" className='dash-home-complete__bg' src={require('../assets/images/dash-complete.png')} />
      </div>
    </div>
  );
};

DashComplete.defaultProps = {
  defaultClass: 'fade-section',
  visibleClass: 'fade-section--visible',
};

export default DashComplete;
