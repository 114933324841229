import React, { useState } from 'react';
import Typing from 'react-typing-animation';
import { useInView } from 'react-intersection-observer';

const DashExample = () => {
  const [ref, inView] = useInView({
    rootMargin: '0px 0px -100px 0px',
    triggerOnce: true,
  });
  const [typeComplete, setTypeComplete] = useState(false);
  return (
    <section className="dash-home-example">
      <div className="dash-home-example__content">
        <h2 className="arrow">Here&apos;s how it works:</h2>
        <div className="slack-chatbox">
          <div className="slack-chatbox__text">
            <div ref={ref} className={`typeout${typeComplete ? ' typeout--complete' : ''}`}>
              {inView ? (
                <Typing
                  speed={20}
                  startDelay={500}
                  hideCursor={true}
                  onFinishedTyping={() => setTypeComplete(true)}
                >
                  /dash project huddle{' '}
                </Typing>
              ) : null}
              <span className="slack-user slack-user-1">@jeremy</span>{' '}
              <span className="slack-user slack-user-2">@gina</span>{' '}
              <span className="slack-user slack-user-3">@phil</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashExample;
