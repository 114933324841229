import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import ScrollToTopOnMount from './components/ScrollToTopOnMount';
import { SUPPORT_EMAIL } from './config';

import './assets/styles/document/index.scss';

interface IDocumentPageProps {
  children: React.ReactNode;
  title: string;
}

export default function DocumentPage({ children, title }: IDocumentPageProps) {
  return (
    <DocumentTitle title={`${title} | Dash for Slack`}>
      <div className="document-page">
        <ScrollToTopOnMount />
        <div className="document-page-back">
          <Link to="/">&#8592; Back to Dash</Link>
        </div>
        <div className="document-page-content">
          <Link to="/" className="document-page-logo">
            <img
              alt="Dash Logo"
              src={require('./assets/images/dash-logo.png')}
            />
          </Link>
          {children}
        </div>
        <p className="document-page-contact">
          Any issues? Contact us at{' '}
          <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
        </p>
      </div>
    </DocumentTitle>
  );
}
