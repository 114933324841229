import * as React from 'react';

import { SUPPORT_EMAIL } from '../config';

export default function({ children }: { children: any }) {
  return (
    <div className="container">
      <div className="page">{children}</div>
      <p className="contact">
        Any issues? Contact us at{' '}
        <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
      </p>
    </div>
  );
}
