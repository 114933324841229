import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

declare var gtag: any;

export const withTracker = <P extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<P>
) => {
  const trackPage = (page: string) => {
    if (typeof gtag === 'function') {
        gtag('config', 'UA-64486563-11', { });
    }
  };

  return (props: P) => {
    useEffect(() => {
      trackPage(props.location.pathname);
    }, [props.location.pathname]);

    return <WrappedComponent {...props} />;
  };
};
