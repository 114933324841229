import * as React from 'react';

interface IContent {
  flair: string;
  message: string;
  details: string | React.ReactElement;
  error?: string;
  buttonText: string;
  buttonLink: string;
  browserLink: string;
}

export default function({
  flair,
  message,
  details,
  error,
  buttonText,
  buttonLink,
  browserLink,
}: IContent) {
  return (
    <>
      <img alt="Dash Logo" src={require('../assets/images/dash-logo.png')} />
      <h2>{flair}</h2>
      <h4>{message}</h4>
      <p className="details">{details}</p>
      {error && <p className="error">{error}</p>}
      <div className="row">
        <a className="button" href={buttonLink}>
          {buttonText}
        </a>{' '}
        <a className="browser-link" href={browserLink}>
          or open in your browser
        </a>
      </div>
    </>
  );
}
