import * as React from 'react';
import { useInView } from 'react-intersection-observer'

const FadeImage = ({ src, defaultClass, visibleClass }: { src: string, defaultClass: string, visibleClass: string }) => {
    const [ ref, inView ] = useInView({ rootMargin: '0px 0px -100px 0px', triggerOnce: true });
    const className = inView ? `${defaultClass} ${visibleClass}` : defaultClass;
    return <img alt="Fade" ref={ref} className={className} src={src} />
}

FadeImage.defaultProps = {
    defaultClass: 'fade-image',
    visibleClass: 'fade-image--visible'
};

export default FadeImage;
