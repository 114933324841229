import './App.scss';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import * as React from 'react';

import { withTracker } from './components/withTracker';

import ErrorPage from './ErrorPage';
import HelpPage from './HelpPage';
import HomePage from './HomePage';
import TermsPage from './TermsPage';
import NotFoundPage from './NotFoundPage';
import SlackAuth from './SlackAuth';
import PrivacyPolicyPage from './PrivacyPolicyPage';

class App extends React.Component {
  public render() {
    return (
      <Router>
        <Switch>
          <Route path="/slack-auth" component={withTracker(SlackAuth)} />
          <Route path="/error" component={withTracker(ErrorPage)} />
          <Route path="/help" component={withTracker(HelpPage)} />
          <Route path="/terms-of-service" component={withTracker(TermsPage)} />
          <Route
            path="/privacy-policy"
            component={withTracker(PrivacyPolicyPage)}
          />
          <Route path="/" component={withTracker(HomePage)} exact={true} />
          <Route component={withTracker(NotFoundPage)} />
        </Switch>
      </Router>
    );
  }
}

export default App;
