import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { RouteComponentProps } from 'react-router-dom';
import { parse } from 'query-string';

import Page from './components/Page';
import Content from './components/Content';

const ErrorMessage = () => (
  <div>
    Sorry about that, please{' '}
    <a
      href="mailto:hello+dash@postlight.com"
      style={{ textDecoration: 'underline' }}
    >
      contact support
    </a>
    .
  </div>
);

interface IErrorPage {
  team: string;
  name: string;
}

export default function ErrorPage({
  location: { search },
}: RouteComponentProps) {
  const { team, name } = parse(search);
  return (
    <DocumentTitle title="Channel name already exists | Dash for Slack">
      <Page>
        {name && team ? (
          <Content
            flair="You can’t fax glitter!"
            message="There was an issue with your Dash channel name."
            details={`A channel called #${name} already exists, so you can’t create a new Dash channel with that name.`}
            error="Please choose a new name in Slack."
            buttonText="Go back to Slack"
            buttonLink={`slack://channel?team=${team}`}
            browserLink={`https://slack.com/app_redirect?team=${team}`}
          />
        ) : (
          <Content
            flair="Oop!"
            message="Something went wrong."
            details={<ErrorMessage />}
            buttonText="Go back to Slack"
            buttonLink="slack://"
            browserLink="https://slack.com"
          />
        )}
      </Page>
    </DocumentTitle>
  );
}
