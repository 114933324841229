import * as React from 'react';
import DocumentTitle from 'react-document-title';

import Content from './components/Content';
import Page from './components/Page';

export default function ErrorPage() {
  return (
    <DocumentTitle title="Oop! 404 Not Found | Dash for Slack">
      <Page>
        <Content
          flair="Oop! 404 Not Found."
          message="We couldn't find that page."
          details=""
          buttonText="Go back to Slack"
          buttonLink="slack://"
          browserLink="https://slack.com"
        />
      </Page>
    </DocumentTitle>
  );
}
