import * as React from 'react';

import DocumentPage from './DocumentPage';
import { SUPPORT_EMAIL } from './config';

export default function PrivacyPolicyPage() {
  return (
    <DocumentPage title="Privacy Policy">
      <p>Effective date: May 22, 2019</p>
      <p>
        Postlight Labs LLC (“Postlight Labs”) operates the Dash for Slack app
        (“Service”), available at dashforslack.com.
      </p>
      <p>
        This page informs you of our policies regarding the collection, use, and
        disclosure of personal data when you use our Service and the choices you
        have associated with that data. By using the Service, you agree to the
        collection and use of information in accordance with this policy.
      </p>
      <h1>Information Collection And Use</h1>
      <p>
        We collect several different types of information for various purposes
        to provide and improve our Service to you. These include:
      </p>
      <ul>
        <li>Channel Name and Slack ID</li>
        <li>Dash outcome text</li>
        <li>Dash creator name and Slack ID</li>
        <li>Dash member Slack IDs</li>
        <li>Start date and end date</li>
        <li>Slack team name and URL</li>
      </ul>
      <h2>Usage Data</h2>
      <p>
        We may also collect information about how the Service is accessed and
        used ("Usage Data"). This Usage Data may include information such as
        your browser type, browser version, the pages of our Service that you
        visit, the time and date of your visit, the time spent on those pages,
        unique device identifiers and other diagnostic data.
      </p>
      <h2>Tracking & Cookies Data</h2>
      <p>
        We use cookies and similar tracking technologies to track the activity
        on our Service and hold certain information.
      </p>
      <p>
        Cookies are files with small amount of data which may include an
        anonymous unique identifier. Cookies are sent to your browser from a
        website and stored on your device. Tracking technologies also used are
        beacons, tags, and scripts to collect and track information and to
        improve and analyze our Service.
      </p>
      Use of Data
      <p>Dash uses the collected data for various purposes:</p>
      <ul>
        <li>
          To allow you to participate in interactive features of our Service
          when you choose to do so
        </li>
        <li>To provide customer care and support</li>
        <li>To monitor the usage of the Service</li>
        <li>To detect, prevent and address technical issues</li>
      </ul>
      <h2>Transfer Of Data</h2>
      <p>
        Your information may be transferred to — and maintained on — computers
        located outside of your state, province, country or other governmental
        jurisdiction where the data protection laws may differ than those from
        your jurisdiction.
      </p>
      <p>
        If you are located outside the United States and choose to provide
        information to us, please note that we transfer the data to the United
        States and process it there.
      </p>
      <p>
        Postlight Labs will take all steps reasonably necessary to ensure that
        your data is treated securely and in accordance with this Privacy Policy
        and no transfer of your Personal Data will take place to an organization
        or a country unless there are adequate controls in place including the
        security of your data and other personal information.
      </p>
      Disclosure Of Data
      <p>
        Dash may disclose your Personal Data in the good faith belief that such
        action is necessary to:
      </p>
      <ul>
        <li>To comply with a legal obligation</li>
        <li>To protect and defend the rights or property of Dash</li>
        <li>
          To prevent or investigate possible wrongdoing in connection with the
          Service
        </li>
        <li>
          To protect the personal safety of users of the Service or the public
        </li>
        <li>To protect against legal liability</li>
      </ul>
      <h2>Security Of Data</h2>
      <p>
        The security of your data is important to us, but remember that no
        method of transmission over the Internet, or method of electronic
        storage is 100% secure. While we strive to use commercially acceptable
        means to protect your Personal Data, we cannot guarantee its absolute
        security.
      </p>
      Service Providers
      <p>
        We employ Google Analytics to perform Service-related services or to
        assist us in analyzing how our Service is used. Google Analytics is a
        free web analytics tool offered by Google to help us analyze your use of
        Dash. The terms of service for Google Analytics is located here:
      </p>
      <p>
        <a href="https://www.google.com/analytics/terms/us.html">
          https://www.google.com/analytics/terms/us.html
        </a>
      </p>
      <h2>Changes To This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.
      </p>
      <p>
        We will let you know via email and/or a prominent notice on our Service,
        prior to the change becoming effective and update the "effective date"
        at the top of this Privacy Policy.
      </p>
      <p>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us:
      </p>
      <ul>
        <li>
          By email: <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
        </li>
      </ul>
    </DocumentPage>
  );
}
