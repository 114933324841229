import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { parse } from 'query-string';
import DocumentTitle from 'react-document-title';

import Page from './components/Page';
import Content from './components/Content';

export default function({ location: { search } }: RouteComponentProps) {
  const { link, team, channel, name = '#-your-new-room' } = parse(search);
  const title = link ? "New Dash Channel created!" : "Dash for Slack Authorized";
  return (
    <DocumentTitle title={`${title} | Dash for Slack`}>
      <Page>
        {link ? (
          <Content
            flair="Ka-kow!"
            message="You made a new Dash channel"
            details="Your new channel is a great place to have important conversations with your team with a time limit in mind."
            buttonText={`Open ${name} in Slack`}
            buttonLink={`slack://channel?team=${team}&id=${channel}`}
            browserLink={link as string}
          />
        ) : (
          <Content
            flair="Boosh!"
            message="You’ve authorized Dash for Slack for your workspace."
            details="Introduce your team by using /dash to create new channels and closing them when done."
            buttonText="Go back to Slack"
            buttonLink={`slack://channel?team=${team}`}
            browserLink={'https://slack.com/app_redirect?channel=general'}
          />
        )}
      </Page>
    </DocumentTitle>
  );
}
