import * as React from 'react';

import DocumentPage from './DocumentPage';

export default function HelpPage() {
  return (
    <DocumentPage title="FAQ">
      <h1>What is Dash for Slack?</h1>
      <p>
        Dash is a way to quickly gather people across Slack into a new temporary
        and time-boxed channel to get work done.
      </p>
      <p>
        Just use the /dash command to create a new channel with the team members
        you’d like to invite. You’ll be asked to provide an end date and time
        and decide whether your channel will be private or public. It works just
        like a regular Slack channel, but the deadline keeps the topic focused
        and goal-oriented. You can easily share the results to another channel
        that the task is done and archive the Dash channel easily to keep things
        tidy. Boosh!
      </p>
      <p>
        Dash channels are perfect for gathering team members across your
        workspace in your organization and keeping your main channels nice and
        tidy. Think about using them for launching a new feature for your
        website, keeping track of everyone on a business trip or planning your
        next happy hour. It’s also way better than using group DMs! Dash
        channels are prepended with a dash to keep them at the top of mind and
        easier to find in searches both when open and archived.
      </p>
      <h2>How do I use Dash?</h2>
      <p>To use Dash for slack, type this one-line command anywhere:</p>
      <code>/dash project name @participant-1 @participant-2 …</code>
      <p>
        For example: Let’s say you’re planning a company offsite with a few
        members of your team:
      </p>
      <code>/dash may offsite @matt @gina @phil @jeremy @adam</code>
      <p>
        You’ll be asked to set the end date and time for when your Dash is
        complete as well as set the channel’s privacy. Your new Dash channel
        #-may-offsite will be created and your team members will be invited.
      </p>
      <p>
        Once the end date happens, the creator of the Dash channel will be asked
        to Finish & Archive the channel. That’s a great time to share the
        outcome with other channels so your team can know about all the work you
        did!
      </p>
      <h1>FAQs</h1>
      <h2>I found a problem and/or I have an idea for Dash!</h2>
      <p>
        We have created a Google Form for you to{' '}
        <a href="https://forms.gle/ncXdKL3iUt4AZ9BZ8">drop us some feedback</a>.
        We are eager to hear your thoughts, so please be as open and honest
        about this as you can!
      </p>
      <h2>Can Dash read all of my Slack messages?</h2>
      <p>
        Nope! To help us troubleshoot any problems, the Dash team can only see
        the following details in our database:
      </p>
      <ul>
        <li>The names of public Dash channels</li>
        <li>The closing message of Dash channels</li>
        <li>The IDs of members invited to Dash channels</li>
      </ul>
      <h2>
        Oops, I don’t need this channel anymore. How can I close a Dash channel
        early?
      </h2>
      <p>
        Visit your Dash dashboard by looking at the conversation with Dash in
        Slack. Just hit Command+K and search for Dash. A menu next to each of
        the Dash channels will give you options to end or change the end date.
        You can also just /part a Dash channel and it’ll expire on its own.
      </p>
      <h2>I’m over it. How do I uninstall Dash?</h2>
      <p>
        Sad to see you go! You can uninstall Dash by revoking access in your
        Slack’s admin.
      </p>
    </DocumentPage>
  );
}
